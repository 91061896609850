import api from "@/api";

const record = {
  listApi(params) {
    return api.get(`/record/api`, {
      params: params,
    });
  },
  listHandle(params) {
    return api.get(`/record/handle`, {
      params: params,
    });
  },
  listLogin(params) {
    return api.get(`/record/login`, {
      params: params,
    });
  },
};

export default record;
